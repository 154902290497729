<template>
    <div
        v-if="isVisible"
        class="row my-3"
    >
        <div class="col-12 col-lg-9">
            <recess-alert 
                :text="message" 
                :type="type" 
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StaticAlert',
    props: {
        type: {
            type: String,
            default: null
        },
        message: {
            type: String,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true
        }
    }
}
</script>